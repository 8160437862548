import isEmpty from "lodash/isEmpty";
import cloneDeep from "lodash/cloneDeep";
import client from "@/data/wp/apollo/client";
import { GET_CATEGORY_TRANSLATIONS } from "@/data/wp/operations/queries/get-categories";
import { GET_POST_CATEGORY } from "../operations/queries/get-post-category";
import { getLanguageCodeFromNextLocale } from "@/utils/localization";
import { GET_SIDEBAR_MENUS } from "../operations/queries/get-menus";
import { buildPageUrl, PageType } from "@/utils/router";
import { getProductsByEspotName, getProductById, getProductByPartNumbers } from "@/data/hcl/sdk";
import { mapProductItemToCardList } from "@/data/hcl/sdk/utils";
import { excerptHtml, toHex } from "@/utils/miscellaneous";
import { storeId, langId } from "../../../utils/storeInfo";
import { getStructuralData } from "@/data/wp/sdk";

/*
 * First enter on the IT category slug (es: 'realizzazioni'),
 * and search the slug of the corresponding category in the current language.
 */
export const getCategoryTranslatedSlug = async (locale: string, categorySlug: string, categoryLanguage: string) => {
  let slug: string = '';
  const { data: catTranslations } = await getCategoryTranslations(categoryLanguage, categorySlug);
  const mainCategory = catTranslations?.categories?.edges?.[0]?.node;
  if (mainCategory) {
    if (mainCategory.language.slug === locale) {
      slug = mainCategory.slug;
    } else {
      const translated = mainCategory?.translations?.find((translation: any) => {
        return translation.language.slug === locale;
      });
      if (translated) {
        slug = translated.slug;
      }
    }
  }
  return slug;
}

export const getCategoryTranslations = async (locale: string, slug: string) => {
  return await client.query({
    query: GET_CATEGORY_TRANSLATIONS,
    variables: {
      language: locale,
      slug: decodeURIComponent(slug),
    }
  });
}

export const getCategoryRawData = async (locale: string, slug: string) => {
  let lastApolloQueryResult: any;
  let allItems: any[] = [];
  let errors: any;
  let hasNextPage = true;
  let endCursor = null;

  // Wordpress restituisce massimo i primi 100 post (ignorando valori maggiori del parametro "first" della query graphql).
  // Per ottenere tutti i post, quindi devo ciclare le info da:
  //  pageInfo { hasNextPage endCursor }
  while (hasNextPage) {
    lastApolloQueryResult = await client.query({
      query: GET_POST_CATEGORY,
      variables: {
        language: getLanguageCodeFromNextLocale(locale),
        slug: decodeURIComponent(slug),
        endCursor: endCursor,
      },
    });
    const {data, errors } = lastApolloQueryResult;
    const newItems = data?.categories?.nodes[0]?.posts?.edges || [];
    allItems = [...allItems, ...newItems];
    errors
    hasNextPage = data?.categories?.nodes[0]?.posts?.pageInfo?.hasNextPage;
    endCursor = data?.categories?.nodes[0]?.posts?.pageInfo?.endCursor;
  }

  if (lastApolloQueryResult?.data?.categories?.nodes[0]?.posts?.edges) {
    lastApolloQueryResult.data.categories.nodes[0].posts.edges = allItems;
  }
  if (lastApolloQueryResult) {
    lastApolloQueryResult.errors = errors;
  }
  return lastApolloQueryResult;
}

export const getMenuRawData = async (locale: string, sideMenus: any) => {
  return client.query({
    query: GET_SIDEBAR_MENUS(sideMenus),
    variables: {
      language: getLanguageCodeFromNextLocale(locale),
    },
  });
}

export const getEspotByName = async (espotName: string) => {
  return getProductsByEspotName(storeId,langId,espotName);
}

export const getProductDataById = async (productId: string) => {
  return getProductById(storeId,langId,productId);
}

/**
 * Arricchisce originalData di _structuralData con le info relative a header e footer
 */
export const loadStructuralData = async (originalData: any, locale: string) => {
  const { data, errors } = await getStructuralData(locale);
  if (data) {
    originalData._structuralData = data;
  }
  return { data, errors };
}

/**
 * Arricchisce ciascuna fascia di fasceData con le info eventualmente necessarie.
 */
export const loadDataForFasce = async (locale: string, structuralData: any, nodeData: any, fasceData: any, params: any) => {
  let sideContentCategoryData: any;
  let postCategoryData: {
    [key: string]: any
  } = {};

  const checkPostData = async (categorySlug: string) => {
    const categorySlugDecoded = decodeURIComponent(categorySlug);
    if (postCategoryData.hasOwnProperty(categorySlugDecoded)) {
      postCategoryData[categorySlugDecoded];
    } else {
      if (decodeURIComponent(nodeData?.slug) === categorySlugDecoded) {
        postCategoryData[categorySlugDecoded] = nodeData;
      } else {
        const { data } = await getCategoryRawData(locale, categorySlugDecoded);
        if (data) {
          postCategoryData[categorySlugDecoded] = data.categories?.nodes?.[0];
        } else {
          postCategoryData[categorySlugDecoded] = undefined;
        }
      }
    }
    return postCategoryData[categorySlugDecoded];
  }

  const getEnichedCategoryDataForFascia = async (categorySlug: string) => {
    const categorySlugDecoded = decodeURIComponent(categorySlug);
    let _data: any = {
      _dataNotLoaded: true
    };
    const categoryData = await checkPostData(categorySlugDecoded);
    if (categoryData) {
      _data = {
        _mainCategorySlug: categorySlugDecoded,
        _subCategories: categoryData?.children?.edges,
        _subCategoriesCounter: {},
        _posts: categoryData?.posts?.edges,
      };

      // enrich wp data
      _data._posts?.map((item: any) => {
        const itemData = item.node;
        itemData._subCategory = cloneDeep(itemData.categories?.edges?.find((cat: any) => cat?.node?.parentId !== null)) || null;
        if (itemData._subCategory) {
          _data._subCategoriesCounter[itemData._subCategory.node.id] = ++_data._subCategoriesCounter[itemData._subCategory.node.id] || 1;
        }
      });
    }
    return _data;
  }

  const getProductList = async (fascia: any, params: any) => {
    let prodList: any = {_products: []};
    let productList : any = [];
    fascia?.products.forEach(async (item: any) => {
      item?.id && productList.push(toHex(item.id));
    });
    if (productList?.length > 0) {
      let _data: any = {
        _dataNotLoaded: true
      }
      console.log('HCL_GRAPHQL_INTERNAL_URL: ' + process.env.HCL_GRAPHQL_INTERNAL_URL);
      _data = await getProductByPartNumbers(storeId,langId,productList);
      if(_data){
        prodList._products = await Promise.all(_data?.data?.productViewFindProductByPartNumbers?.catalogEntryView?.map(mapProductItemToCardList.bind(null,storeId, "", params, locale, langId)));
      }
    }
    return prodList;
  }

  const checkSideContentData = async (sideMenus: any) => {
    if (!sideContentCategoryData) {
      const { data } = await getMenuRawData(locale, sideMenus);
      if (data) {
        sideContentCategoryData = data;
      }
    }
  }

  if (!isEmpty(fasceData) && fasceData.fasce) {
    for (let i = 0; i < fasceData.fasce.length; i++) {
      const fascia = fasceData.fasce[i];
      const type = fascia.__typename.split('_').pop();
      switch (type) {
        case 'RealizzazioniList':
        case 'NewsEventiFiereList':
        case 'PostList':
          fascia._data = await getEnichedCategoryDataForFascia(fascia?.category?.slug);
          break;

        case 'ProductList':
          fascia._data = await getProductList(fascia, params);
          break;

        case 'SidedContent':
        case 'Contacts':
          await checkSideContentData(fascia.sideMenus);

          fascia._data = {
            _pageTitle: nodeData?.title || nodeData?.name || null,
            _pageContent: nodeData?.content || null,
            _sideMenus: sideContentCategoryData,
          };
          break;

        case 'FullContent':
          fascia._data = {
            _pageTitle: nodeData?.title || nodeData?.name || null,
            _pageContent: nodeData?.content || null,
          };
          break;

        case 'BrandCategories':
          fascia._data = {
            _structuralData: structuralData
          };
          break;
      }
    }
  }
}

/**
 * Dato un item contenente i dati di un post nel formato restituito da Wordpress,
 * effettua il mapping restituendo un oggetto da passare al componente WhiteCard o, sottoforma di array, CardList.
 *
 * Usage example:
 *  const cardListPosts = paginatedData.currentData()?.map(mapPostAPIToCardList);
 */
export const mapPostAPIToCardList = (item: any) => {
  const itemData = item?.node;
  const rootCategory = itemData.categories?.edges?.find((cat: any) => cat?.node?.parentId === null);

  let detailUrl = '';
  if (itemData.contentTypeName === 'page') {
    detailUrl = buildPageUrl(PageType.PAGE, { slug: itemData?.slug });

  } else if (itemData.contentTypeName === 'post') {
    detailUrl = buildPageUrl(PageType.POST_DETAIL, { category: rootCategory?.node?.slug, slug: itemData?.slug });
    if (itemData.fieldsArticoli?.pdf) {
      detailUrl = itemData.fieldsArticoli.pdf?.mediaItemUrl;
    }
    if (itemData.fieldsArticoli?.flipbookUrl) {
      detailUrl = itemData.fieldsArticoli.flipbookUrl;
    }
  }

  return {
    tag: itemData._subCategory?.node?.name,
    url: detailUrl,
    img: itemData.featuredImage?.node,
    occhiello: itemData.fieldsRealizzazioni?.locality,
    title: itemData.title,
    text: itemData.excerpt || excerptHtml(itemData.content),
  };
}
